body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}


a {
  text-decoration: none;
}

* {
  box-sizing: border-box;
}

 @font-face {
  font-family: "Proxima Nova";
  font-weight: 500;
  src: url("Assets/fonts/proxima-nova-bold.ttf") format("truetype");
  font-display: swap;
}
@font-face {
  font-family: "Proxima Nova";
  font-weight: 400;
  src: url("Assets/fonts/proxima-nova-regular.ttf") format("truetype");
}

/* Hide scrollbar for Chrome, Safari and Opera */
*::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
* {
  -ms-overflow-style: none;  /* IE and Edge */
  scrollbar-width: none;  /* Firefox */
}
